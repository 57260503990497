import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { compose } from 'redux';
import { connect, useDispatch } from 'react-redux';
import { FormattedMessage, injectIntl, intlShape } from '../../util/reactIntl';
import { propTypes } from '../../util/types';
import { ensureCurrentUser } from '../../util/data';
import { isScrollingDisabled } from '../../ducks/UI.duck';
import { Page, DashboardSidebar, NamedLink, Logo, IconSpinner } from '../../components';
import TopbarContainer from '../TopbarContainer/TopbarContainer';
import ShippingDetailsForm from './ShippingDetailsForm/ShippingDetailsForm';
import { withViewport } from '../../util/contextHelpers';
import { updateProfile } from '../ProfileSettingsPage/ProfileSettingsPage.duck';
import { getOwnShippingDetails, getOwnShippingPreference } from '../../util/dataExtractors';
import {
  createShippoAddress,
  fetchCurrentUserListings,
  saveWeightToAllListings,
} from './ShippingDetailsPage.duck';
import { types as sdkTypes } from '../../util/sdkLoader';
import { FREE, OWN, RESPONSE, SHIPPING, TUTULIST_LOWERCASE, YES } from '../../util/enums';
import { Flip, toast } from 'react-toastify';

import css from './ShippingDetailsPage.module.css';

const { Money } = sdkTypes;

export const ShippingDetailsPageComponent = props => {
  const {
    currentUser,
    scrollingDisabled,
    intl,
    viewport,
    updateProfileError,
    // updateInProgress,
    // profileUpdated,
    onUpdateProfile,
    onCreateShippoAddress,
    saveShippoAddressInProgress,
    shippoAddressSaved,
    saveShippoAddressError,
    onSaveWeightToAllListings,
    saveWeightInProgress,
    clearForm,
    fetchListingsInProgress,
  } = props;

  const isMobileLayout = viewport.width > 0 && viewport.width < 768;
  const user = ensureCurrentUser(currentUser);
  const dispatch = useDispatch();
  const shippingPreference = getOwnShippingPreference(user);
  const [currentTab, setCurrentTab] = useState(shippingPreference);

  const [TTlWeightWrapper, setTTlWeightWrapper] = useState(false);
  const [ownWeightWrapper, setOwnWeightWrapper] = useState(false);
  const [hasAnyStockGreaterThanOne, setHasAnyStockGreaterThanOne] = useState(false);

  const [isTTLPreferenceSet, setIsTTLPreferenceSet] = useState(false);

  const shippingDetails = getOwnShippingDetails(user) || null;
  const protectedData = user?.attributes?.profile?.protectedData;
  const publicData = user?.attributes?.profile?.publicData;

  const onTriggerError = () => {
    toast.error(
      `Sorry, we encountered an error. Please try again later. If the issue persists, kindly contact our support team for further assistance.`,
      {
        position: 'top-right',
        autoClose: 3000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: 'colored',
        transition: Flip,
      }
    );
  };

  useEffect(() => {
    if (updateProfileError) {
      onTriggerError();
    }
    if (currentUser && currentUser?.id) {
      dispatch(fetchCurrentUserListings(currentUser?.id?.uuid, currentTab)).then(res => {
        if (res?.weightMissingAndTTLShipping) {
          setIsTTLPreferenceSet(true);
          setTTlWeightWrapper(!!res?.weightMissingAndTTLShipping);
          setOwnWeightWrapper(false);
        } else if (res?.weightMissingAndOwnShipping) {
          setOwnWeightWrapper(!!res?.weightMissingAndOwnShipping);
          setTTlWeightWrapper(false);
          setHasAnyStockGreaterThanOne(!!res?.isAnyStockGreaterThanOne);
        } else {
          setIsTTLPreferenceSet(false);
        }
      });
    }
    setTTlWeightWrapper(false);
  }, [updateProfileError, currentUser?.id?.uuid, currentTab]);

  const onSaveDefaultToAllListings = async values => {
    const res = await onSaveWeightToAllListings({ ...values, id: currentUser?.id?.uuid });
    if (res === RESPONSE.SUCCESS) {
      setTTlWeightWrapper(false);
      setOwnWeightWrapper(false);
      setIsTTLPreferenceSet(values?.isShippingParams ? false : true);
    }
  };

  const shippingInfoForm = user.id ? (
    <ShippingDetailsForm
      key={clearForm}
      className={css.form}
      phoneNumber={protectedData?.phoneNumber}
      isShippingDetailsPage={true}
      initialValues={{
        shippingPreference: currentTab || OWN,
        line1: shippingDetails?.line1,
        line2: shippingDetails?.line2,
        city: shippingDetails?.city,
        state: shippingDetails?.state,
        postalCode: shippingDetails?.postalCode,
        country: shippingDetails?.country,
        enableUSOrders: publicData?.enableUSOrders,
        disableNonUSShipping: publicData?.disableNonUSShipping,
        threshold: isTTLPreferenceSet
          ? 'no'
          : protectedData?.otherShippingPreferences?.threshold || 'no',
        length: protectedData?.otherShippingPreferences?.length,
        width: protectedData?.otherShippingPreferences?.width,
        height: protectedData?.otherShippingPreferences?.height,
        weight: protectedData?.otherShippingPreferences?.weight,
        phoneNumber: protectedData?.phoneNumber,
        shippingType: isTTLPreferenceSet
          ? SHIPPING
          : protectedData?.otherShippingPreferences?.shippingType || SHIPPING,
        thresholdAmount:
          protectedData?.otherShippingPreferences?.threshold === YES
            ? new Money(
                protectedData?.otherShippingPreferences?.thresholdAmount,
                protectedData?.otherShippingPreferences?.thresholdCurrency
              )
            : '',
      }}
      currentUser={currentUser}
      onSubmit={async values => {
        const {
          line1,
          line2,
          city,
          state,
          postalCode,
          country,
          shippingType = null,
          threshold = null,
          thresholdAmount = null,
          length = null,
          width = null,
          height = null,
          weight = null,
          enableUSOrders = null,
          disableNonUSShipping = null,
          shippingPreference,
          phoneNumber,
          ...rest
        } = values;

        const isFreeShipping = shippingType === FREE;

        const tutulistShippingParams = {
          threshold: isFreeShipping ? null : threshold,
          thresholdAmount: threshold === YES && !isFreeShipping ? thresholdAmount?.amount : null,
          thresholdCurrency:
            threshold === YES && !isFreeShipping ? thresholdAmount?.currency : null,
          shippingType,
        };

        const otherShippingPreferences = {
          length,
          width,
          height,
          weight,
        };

        if (shippingPreference === TUTULIST_LOWERCASE) {
          Object.assign(otherShippingPreferences, { ...tutulistShippingParams });
        }

        const shippingDetails = { line1, line2, city, state, postalCode, country, phoneNumber };
        await onCreateShippoAddress({ shippingDetails });
        await onUpdateProfile({
          protectedData: {
            shippingDetails,
            shippingPreference,
            otherShippingPreferences,
            phoneNumber,
            ...rest,
          },
          publicData:
            shippingPreference === OWN
              ? {
                  shippingPreference,
                  enableUSOrders,
                  disableNonUSShipping,
                }
              : {
                  shippingType,
                  thresholdAmount: isFreeShipping
                    ? null
                    : threshold === YES
                    ? thresholdAmount?.amount
                    : null,
                  defaultDimensions: {
                    length,
                    width,
                    height,
                  },
                  enableUSOrders,
                  shippingPreference,
                  disableNonUSShipping,
                },
        });
      }}
      inProgress={saveShippoAddressInProgress}
      ready={shippoAddressSaved}
      updateProfileError={updateProfileError || saveShippoAddressError}
      setCurrentTab={setCurrentTab}
      onSaveDefaultToAllListings={onSaveDefaultToAllListings}
      TTlWeightWrapper={TTlWeightWrapper}
      saveWeightInProgress={saveWeightInProgress}
      ownWeightWrapper={ownWeightWrapper}
      hasAnyStockGreaterThanOne={hasAnyStockGreaterThanOne}
    />
  ) : null;

  const title = intl.formatMessage({ id: 'ShippingDetailsPage.title' });

  return (
    <Page title={title} scrollingDisabled={scrollingDisabled}>
      <div className={css.dashboardPage}>
        {isMobileLayout ? null : (
          <div className={css.sideBar}>
            <NamedLink className={css.logoLink} name="LandingPage">
              <Logo className={css.logo} format="mobile" />
            </NamedLink>
            <DashboardSidebar />
          </div>
        )}
        <div className={css.mainContent}>
          <div className={css.header}>
            <TopbarContainer currentPage="ShippingDetailsPage" dashboardHeader={true} />
          </div>

          <div className={css.pageContent}>
            <div className={css.fixedWidthLayout}>
              <div className={css.payoutsContent}>
                <h1 className={css.title}>
                  <FormattedMessage id="ShippingDetailsPage.heading" />
                </h1>
                <div className={css.content}>
                  {saveShippoAddressInProgress || fetchListingsInProgress ? (
                    <div className={css.spinner}>
                      <IconSpinner />
                    </div>
                  ) : (
                    shippingInfoForm
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Page>
  );
};

ShippingDetailsPageComponent.defaultProps = {
  currentUser: null,
};

const { bool } = PropTypes;

ShippingDetailsPageComponent.propTypes = {
  currentUser: propTypes.currentUser,
  scrollingDisabled: bool.isRequired,
  // from injectIntl
  intl: intlShape.isRequired,
};

const mapStateToProps = state => {
  // Topbar needs user info.
  const { currentUser } = state.user;
  const { updateInProgress, updateProfileError, profileUpdated } = state.ProfileSettingsPage;
  const {
    saveShippoAddressInProgress,
    shippoAddressSaved,
    saveShippoAddressError,
    saveWeightInProgress,
    clearForm,
    fetchListingsInProgress,
    listingsFetched,
  } = state.ShippingDetailsPage;

  return {
    updateInProgress,
    updateProfileError,
    profileUpdated,
    currentUser,
    saveShippoAddressInProgress,
    shippoAddressSaved,
    saveShippoAddressError,
    saveWeightInProgress,
    listingsFetched,
    clearForm,
    fetchListingsInProgress,
    scrollingDisabled: isScrollingDisabled(state),
  };
};

const mapDispatchToProps = dispatch => ({
  onUpdateProfile: data => dispatch(updateProfile(data)),
  onCreateShippoAddress: params => dispatch(createShippoAddress(params)),
  onSaveWeightToAllListings: params => dispatch(saveWeightToAllListings(params)),
});

const ShippingDetailsPage = compose(
  connect(mapStateToProps, mapDispatchToProps),
  injectIntl,
  withViewport
)(ShippingDetailsPageComponent);

export default ShippingDetailsPage;
